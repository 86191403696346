@import '../../styles/utils.scss';
.el {
  --border-style: 1px solid #C9C9D7;

  width: 100%;
  padding-top: 80px;
  background: $color-grey;

  .container {
    max-width: $container-md;
    border-bottom: var(--border-style);

    @include tablet {
      height: inherit;
      flex-direction: column;
    }
    .content {
      display: flex;
      @include tablet {
        display: block;
      }
    }
    .title {
      padding-right: 40px;
      margin-bottom: 15px;
      min-width: 37%;
      @include tablet {
        padding-right: 0;
      }
    }
    .description {
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
    }
    .expandableFooter {
      height: 60px;
      line-height: 60px;
      text-align: right;
      margin-right: 18px;
      margin-top: 30px;
      overflow: hidden;

      .expandableLaunch {
        display: inline-block;
        border-left: var(--border-style);

        button {
          padding: 0 45px 0 30px;
          color: $color-orange;
          text-transform: uppercase;
          font-weight: bold;

          span {
            display: inline-block;
            font-size: 24px;
            transition: transform 0.3s;
            transform: translateX(20px);

            &:before {
              vertical-align: middle;
            }
          }

          &:hover span {
            transform: translateX(40px);
          }
        }

        .flipArrow {
          span {
            transform: translateX(20px) scaleY(-1);
          }

          &:hover {
            span {
              transform: translateX(40px) scaleY(-1);
            }
          }
        }
      }
    }
  }

  .expandable {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;

    // Also style the child components differently for this context
    > *:first-child {
      margin-top: 0;

      *, > *:first-child {
        border-top: none;
      }
    }
    
    ul{
      margin: 0 0 20px 20px;
      padding: 0;
      li{
        list-style: disc;
      }
    }
  }
}
