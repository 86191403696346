@import '../../styles/utils.scss';
.container {
  max-width: $container-md;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 18px;
}

.officeWrapper {
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid $color-blue-25;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  @include tablet{
    flex-direction: column;
  }
  .leftItem {
    width: 100%;
    gap:30px;
    border-bottom: 1px solid $color-blue-25;
    margin-bottom: 60px;
    top: 80px;
    @include tablet{
      width: 100%;
      position: relative;
      top: 0;
    }
    h2 {
      text-transform: capitalize;
    }
    .regionWrapper {
      padding: 20px 0 20px 0;
      display: flex;
      .regionName {
        margin: 20px 80px;
        padding-left: 50px;
        span{
          transition: .3s;
          cursor: pointer;
          @include tablet{
          }
          &:hover{
            color: $color-orange;

          }
          &.isActive{

            color: $color-orange;

          }

        }
        @include tablet{
          padding-left: 0;
        }
      }
      @include tablet {
        flex-direction: column;
        .regionName {
          margin-left: 0;
        }
      }
    }
  }
  .rightItem {
    margin-bottom: 20px;
    width: 100%;
    @include tablet{
      width: 100%;
    }
    h4 {
      padding-bottom: 20px;
      @include tablet{
      }
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: $color-blue;
      padding-top: 10px;
      @include tablet{
        font-size: 14px;
        line-height: 20px;
      }
    }

    .gotoLink {
      margin-top: 23px;
      a {
        color: $color-orange;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
      }
    }
    .tel {
      a {
        color: $color-blue;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.03em;
        &:hover {
          p {
            color: $color-orange;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }

    .addressCards {
      .addressCard {
        padding: 40px;
        background-color: #f7f7f7;
        .contactDetails{
          margin-bottom: 20px;
          p {
            line-height: 20px;
            padding-top: 5px;
            font-weight: 600;
          }
        }
        a {
          color: $color-blue;

          &:hover {
            color: $color-orange;
          }
        }
      }
    }
  }
}
