@import '../../styles/utils.scss';

.section {
  max-width: $container-fhd;
  margin: 0 auto;
}
.heroBannerWrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  /* align-items: flex-end; */
  height: 100%;
  width: 100%;
  text-align: left;
  .container {
    max-width: $container-md;
  }
  .bannerTop {
    border-bottom: 1px solid #fff;
    padding: 37px 0;
    @include tablet{
      padding: 19px 0;
    }
  }
  h1 {
    color: #fff;
    max-width: 800px;
    @include tablet{
    }
  }
  .bannerBottom {
    min-height: 82px;
    @include tablet{
      min-height: 30px;
    }
  }
  .bannerDesc {
    padding: 35px 0 44px;
    max-width: 780px;
    @include tablet{
      padding: 30px 0 39px;
    }
    &, p {
      font-size: 18px;
      line-height: 1.3;
      color: #fff;
      @include tablet{
      }
    }
  }
}
.staticBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;

  @include tablet{
    height: 520px;
  }
}
