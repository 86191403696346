@import '../../styles/utils.scss';

.el {
  width: 100%;

  .container {}

  .headline {}

  .desc {
    max-width: 820px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.03em;
    text-align: left;
    margin-top: 15px;
  }
}
