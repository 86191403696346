@import '../../styles/utils.scss';

.el {
    width: 100%;
    margin-top:-2px;

    .container {
        display: flex;
        padding: 0;
        background-color: #fff;
        max-width: $container-fhd;

        @include tablet {
            height: inherit;
            flex-direction: column;
        }

        &.isMediaLeft {
            flex-direction:row;
            .text {
                @media screen and (min-width: $container-md) {
                  padding: 30px 96px;
                  padding-right: calc((100% - 1440px) / 2 + $container-p);
                }
            }
            background-color: $color-grey;
        }

        &:nth-child(odd) {
            @include tablet {
                flex-direction: column;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;
            @include tablet {
                flex-direction: column;
            }
        }

        &.onlyOne{
            @include tablet {
                flex-direction: column-reverse;
            }
        }
    }

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        padding: 30px 6.66vw;

        @media screen and (min-width: $container-md) {
          padding: 30px 96px;
          padding-left: calc((100% - 1440px) / 2 + $container-p);
        }
        @include desktop {
            padding: $container-p;
        }
        @include tablet{
            width: 100%;
            height: inherit;
            padding-top: 60px;
            padding-bottom: 80px;
            padding-left: $mobile-padding;
            padding-right: $mobile-padding;
        }
        @include mobile {
          min-height: 100vw;
        }

        &.videoText {
            background-color: $color-grey;

            @include tablet {
                padding: 60px 20px;
            }

            .title {
                width: 100%;
            }
        }

        .title {
            width: 100%;

            @include desktop {
                width: 100%;
            }
        }

        .desc {
            margin-top: 15px;
            width: 100%;
            font-size: 18px;
            line-height: 23px;
            font-weight: 400;

            @include desktop {
                width: 100%;
            }
        }
    }

    .quote {
        @include tablet{
            width: 100%;
            height: inherit;
            padding-left: 0;
            padding-right: 0;
        }

        .headline {
            font-style: italic;
            font-weight: 500;
            text-align: left;
            margin-top: 40px;
            @include desktop {
                margin-top: 20px;
            }
        }

        .name {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: -0.03em;
            text-align: left;
            margin-top: 60px;
            @include  desktop {
                margin-top: 30px;
            }
        }

        .job {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0.05em;
            text-align: left;
            margin-top: 5px;
        }

        .icon {
            margin-top: 30px;
            @include desktop {
                margin-top: 0;
            }
        }
    }

    .media {
        width: 50%;

        @include tablet{
            width: 100%;
        }
    }

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        aspect-ratio: 1/1;

        .deco {
            width: 82%;
        }
    }

    .player {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;

        video {
            height: 100%;
        }

        .playButton {
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .hidden {
            display: none;
        }
    }

    .imageWrapper {
      position: absolute;
      height: 100%;
      width: 100%;

      img {
        object-fit: cover;
      }
    }

    .videoWrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .videoPoster {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        pointer-events: none;
        z-index: 10;
      }

      .playButton{
        position: absolute;
        cursor: pointer;
        top: 0%;
        left: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .vimeoPlayer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;

        video {
            height: 100%;
        }

        .playButton {
            cursor: pointer;
            position: absolute;
            pointer-events: none;
        }

        .hidden {
            display: none;
        }
    }
}
