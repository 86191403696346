@import '../../styles/utils.scss';
.el {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  // border-bottom: 1px solid $color-blue-25;
  @include tablet {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .container {
    max-width: $container-md;
    @include tablet {
      height: inherit;
      flex-direction: column;
    }
    .content {
      display: flex;
      gap: 25px;

      @include tablet {
        display: block;
      }
      & > * {
        width: auto;
        @include tablet {
          width: 100%;
        }
      }

      > h2 {
        flex: 0;
        min-width: 35%;
      }
      > div {
        flex: 1;
        padding-bottom: 25px;
      }
    }
    .title {
      padding-right: 40px;
      margin-bottom: 15px;
      @include tablet {
        padding-right: 0;
      }
    }
    .description {
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
    }
  }
  .reportCard {
    margin-top: 60px;
  }
  .cta {
    margin-bottom:0px;
    border-bottom: 1px solid $color-blue-25;
    .container {
      display: flex;
      justify-content: flex-end;
    }
    .ctaLink {
      padding: 20px 0 20px 40px;
      border-left: 1px solid $color-blue-25;
    }
    .noCtaLink {
      padding: 20px 0 20px 40px;
    }
    a {
      color: $color-orange;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }
}
