@import '../../styles/utils.scss';

.el {
  --loading-padding: 65px;

  .loading {
    width: var(--loading-padding);
    height: var(--loading-padding);
    position: absolute;
    top: calc(100% - var(--loading-padding) - 5px);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  .container {
    padding-bottom: 60px;
    max-width: $container-md;
  }

  .filters {
    margin-top: 80px;

    @include tablet {
      margin-top: 0;
    }
  }

  .filterSelectWrap {
    &+.filterSelectWrap {
      button {
        border-left: none;
      }
    }
  }

  .searchBox {
    width: 100%;
    height: 100%;
    padding: 19px 20px 19px 32px;
    border: 1px solid $color-blue-25;
    color: $color-blue;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.03em;

    @include desktop {
      font-size: 14px;
    }

    @include tablet {
      border-top: none;
      border-left: none;
      border-right: none;
      padding: 19px $mobile-padding 19px;
      font-size: 18px;
    }
  }

  .filtersMobile {
    display: none;
    justify-content: space-between;
    padding: 20px $mobile-padding;
    margin-left: -$mobile-padding;
    margin-right: -$mobile-padding;
    border-bottom: 1px solid $color-blue-25;

    @include tablet {
      display: flex;
    }

    button {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: $color-blue;

      span {
        font-size: 24px;
        margin-right: 10px;
        margin-bottom: 5px;

        &:before {
          display: block;
          margin-bottom: -4px;
        }
      }
    }
  }

  .filterHeadMobile {
    display: none;
    height: 60px;
    border-bottom: 1px solid $color-blue-25;
    padding: 0 $mobile-padding;

    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 200;
      background: #fff;
    }

    h3 {
      line-height: 1.1;
    }

    .backBtn {
      font-size: 24px;
      margin-top: 8px;
      color: $color-blue;

      span {
        display: inline-block;
        transform: scaleX(-1);
      }
    }

    .closeBtn {
      font-size: 24px;
      margin-top: 8px;
      color: $color-blue;
    }
  }

  .filterBottomMobile {
    display: none;
    position: fixed;
    z-index: 101;
    background: #fff;
    width: 100%;
    left: 100%;
    bottom: 0;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 17vh;
    transition: left 0.5s;

    @include tablet {
      display: flex;
    }

    .applyBtn {
      background: $color-orange;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      color: #fff;
      padding: 15px 30px;
      min-width: 230px;
      border-radius: 24px;
      margin-bottom: 10px;
    }
  }

  .filtersWrapper {
    @include tablet {
      left: 100%;
      display: block;
      position: fixed;
      overflow: auto;
      z-index: 100;
      width: 100%;
      height: calc(100vh - 60px);
      background: #fff;
      top: 60px;
      padding: 0 0 calc(17vh + 60px) 0;

      @supports (-webkit-touch-callout: none) {
        padding: 0 0 calc(17vh + 112px) 0;
      }

      transition: left 0.5s;

      &>div {
        width: 100% !important;
        max-width: 100vw;
      }

      &.open {
        left: 0;

        .filterBottomMobile {
          left: 0;
        }
      }
    }
  }

  .selectedFilters {
    .filterButton {
      position: relative;
      background: $color-orange;
      color: #fff;
      border-radius: 19px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      padding: 10px 51px 10px 20px;
      display: inline-flex;
      justify-content: space-between;
      text-transform: uppercase;
      margin-right: 20px;
      margin-bottom: 10px;

      span.cross {
        position: absolute;
        right: 15px;
        top: 17px;

        &:before,
        &:after {
          background: #fff;
        }
      }
    }

    &:global(.mobile) {
      display: none;
      padding: 12px $mobile-padding;
      background: $color-grey;
      width: 100%;
    }

    @include tablet {
      &:global(.desktop) {
        display: none;
      }

      &:global(.mobile) {
        display: block;
      }
    }
  }

  .clearButton {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $color-blue;
    white-space: nowrap;

    @include tablet {
      &:global(.desktop) {
        display: none;
      }
    }

    span {
      display: block;
      font-size: 24px;
      line-height: 16px;
      margin-right: 10px;
    }
  }

  .filtersSort {
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 26px 0 24px;

    @include tablet {
      align-items: center;
    }

    .filterSearch {
      width: 100%;
    }

    .sortSelect {
      width: 100%;
      justify-self: flex-end;
      border: 1px solid #c9c9d7;

      @include mobile {
        min-width: 110px;
        width: 100%;
      }
    }

    &+.numberTeamMembers {
      margin-top: 0;

      @include tablet {
        margin-top: 0;
      }
    }
  }

  .filtersSelected {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .filterResults {
    display: flex;
    gap: 12px;
    flex-shrink: 0;
    margin-top: 8px;
  }

  .numberTeamMembers {
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:empty {
      display: none;
    }

    @include tablet {
      margin-top: 40px;
      justify-content: space-between;
    }

    &.single {
      justify-content: space-between;
    }

    .number {
      text-decoration: underline;
      margin-right: 5px;
    }

    .sortSelect {
      width: 200px;
      border: 1px solid #c9c9d7;

      @include mobile {
        width: 140px;
      }
    }

    &Mobile {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      padding-right: 15px;

      .number {
        text-decoration: underline;
      }
    }
  }

  .seriesTitleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin: 30px 0 100px 0;

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      margin: 20px 0;
    }

    .seriesTitleColumn1 {
      font-size: 18px;

      .seriesTitle {
        font-size: 50px;
        margin-bottom: 15px;
      }
    }

    .seriesTitleColumn2 {
      max-width: 50%;
      min-width: 50%;
      width: 50%;
      background-size: cover;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

      @include tablet {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .seriesCategory {

    display: grid;
    padding: 60px 0 0 0;
  }

  .newsWrapper {
    position: relative;
    padding-bottom: var(--loading-padding);
    margin-top: 18px;
    margin-left: -18px;
    margin-right: -18px;
    border-top: 1px solid #C9C9D7;
    overflow: auto;

    @include tablet {
      margin-bottom: 50px;
    }
  }

  span.cross {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 12px;
    height: 2px;

    &:before,
    &:after {
      content: ' ';
      display: block;
      width: 12px;
      height: 2px;
      background: #fff;
      position: absolute;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .noResults {
    padding: 50px 18px 100px;
    font-size: 36px;
  }

  :global(.mobile) {
    display: none;
  }

  @include tablet {
    :global(.desktop) {
      display: none;
    }

    :global(.mobile) {
      display: block;
    }
  }
}
