@import '../../styles/utils.scss';

.el {
  display: flex;
  flex-wrap: wrap;
  gap: var(--section-margin-standard);

  .statsInfo {
    
  }

  .statsTitle {
    font-size: 50px;
    color: $color-orange;

    @include tablet {
      font-size: 36px;
    }
  }

  .statsDesc {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.03em;
    text-align: left;
  }
}
