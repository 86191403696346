@import '../../styles/utils.scss';

.el {
    padding-bottom: 0;
    // border-bottom: 1px solid $color-blue-25;
    margin: 0 auto 0;
    border-bottom: 1px solid $color-blue-25;
    @include tablet {
      margin-top: 0;
    }
    &.people {
      padding-top: 20px;
      background-color: #F7F7F7;
    }
    .container {
      max-width: $container-md;
    }
  .title {
    text-align: left;
    padding-top: 60px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 40px;
    }
}
.description {
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: -0.03em;
  padding-bottom: 40px;
}
.content{
  .items {
      display: flex;
      flex-wrap: wrap;
      margin-left: -18px;
      margin-right: -18px;
      @include tablet {
        padding: 0 20px;
        flex-direction: column;
      }
      @include mobile {
        flex-direction: column;
      }
      .image {
        display: block;
        justify-content: center;
        align-items: center;
        margin: 0 18px 0;
        width: 100%;
        text-align: left;
        position: relative;
        @include tablet {
          margin: 0 15px 40px;
          width: 100%;
        }
        @include tablet {
          margin: 0 auto;
          width: 100%;
        }
        &.grey {
          background-color: #F7F7F7;
        }
        &.white {
          background-color: #FFF;
        }
      }
  }

}
}
