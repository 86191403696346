@import '../../styles/utils.scss';

.newsHeader {
    text-align: center;
    border-top: 1px solid $color-blue-25;
    border-bottom: 1px solid $color-blue-25;

    padding: 28px 0;
}

.newsSection {
    .container {
        max-width: $container-md;
    }
    border-bottom: 1px solid $color-blue-25;
    .newsWrapper {
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: flex-start;
        margin: 0 -20px;

        @include mobile {
            flex-direction: column;
            margin: 0;
        }

        a {
            color: $color-blue;

            &.primaryItem {
              padding: 20px 0;

              @include mobile {
                padding: 0;
              }
            }

            &:hover {
              text-decoration: none;
            }
        }

        .newsTagsandDate {
            margin-bottom: 15px;

            .newsType {
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                background-color: $color-orange;
                padding: 5px;
                color: #fff;
                margin: 0;
                line-height: 15px;
                display: inline-block;

                @include tablet {
                    margin: 0 30px 10px 0;
                }
                @include mobile {
                    margin: 0 30px 10px 0;
                }
            }

            .newsDate {
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                padding: 5px;
                color: $color-blue;
                margin: 0;
                line-height: 15px;
                display: inline-block;

                @include tablet {
                    margin: 0 0 10px 0;
                }
                @include mobile {
                    margin: 0 0 10px 0;
                }
            }
        }

        .featuresNews {
            margin-bottom: 100px;
            transition: 0.3s;

            @include tablet {
                position: relative;
                margin-bottom: 30px;
            }
            @include mobile {
                top: 20px;
            }

            .featuresTitle {
                margin-bottom: 20px;
            }

            .featuresCardCta {
                position: absolute;
                bottom: 40px;
                opacity: 0;

                font-size: 14px;
                color: $color-orange;
                font-weight: 700;
                line-height: 17px;
                text-transform: uppercase;
                position: relative;

                &:after {
                    content: '\62';
                    position: absolute;
                    font-size: 18px;
                    margin-left: 5px;
                    transition: 0.5s;
                    font-weight: bold;
                    font-family: 'permira' !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }

            &:hover {
                .newsImage {
                    img {
                        transform: translateY(-40px);
                        transition: 0.3s;
                    }
                }
                .featuresCardCta {
                    opacity: 1;
                    transition: 0.3s;

                    &:after {
                        transform: translateX(10px);
                    }
                }
            }
            .newsImage {
                img {
                    height: 622px;
                    @include tablet {
                        height: auto;
                    }
                }
            }
        }

        > div {
            flex: 1 1 50%;
            padding: 20px;
            @include mobile {
                padding: 20px 0;
            }
            &:nth-child(2) {
                border-left: 1px solid $color-blue-25;
                @include mobile {
                    border: none;
                }
            }
        }

        .newsAuthor {
            margin: 20px 0;
            .newsAuthorName {
                font-size: 18px;
                font-weight: normal;
                line-height: 26px;
                color: #000;
                margin-left: 10px;
            }
            span {
                img {
                    height: 48px;
                    width: 48px;
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 100px;
                    filter: grayscale(100%);
                }
            }
        }

        .newsImage {
            overflow: hidden;
            position: relative;
            img {
                object-fit: cover;
                width: 100%;
                height: auto;
                transition: 0.3s;
                z-index: 99;
            }
            .playIcon {
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -24px 0 0 -24px;
                width: 48px;
                height: 48px !important;
            }
        }

        .newsSmallTiles {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: row;
            grid-gap: 1.25rem;
            .newsTileWrapper {
                &:after {
                    transform: translateX(10px);
                }

                .newsInnerCard {
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    padding-bottom: 30px;

                    @include mobile {
                        padding-top: 30px;
                        padding-bottom: 0;
                        border-top: 1px solid $color-blue-25;
                    }

                    .newsImage {
                        img {
                            height: 292px;
                            @include tablet {
                                height: auto;
                            }
                        }
                    }

                    &:hover {
                        .newsImage {
                            img {
                                transform: translateY(-40px);
                                transition: 0.3s;
                            }
                        }
                        .newsCardCta {
                            opacity: 1;
                            transition: 0.2s;
                        }
                    }
                }
            }

            .featuresTitle {
                p {
                    font-size: 18px;
                    line-height: 23px;
                    font-weight: normal;
                    margin-bottom: 20px;
                    @include mobile {
                        font-size: 14px;
                        line-height: 20px;
                    }
                    @include tablet {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            .newsCardCta {
                position: absolute;
                bottom: 40px;
                opacity: 0;

                font-size: 14px;
                color: $color-orange;
                font-weight: 700;
                line-height: 17px;
                text-transform: uppercase;
                position: relative;

                @include mobile {
                    font-size: 12px;
                }

                @include tablet {
                    font-size: 12px;
                    margin-top: 10px;
                }

                &:after {
                    content: '\62';
                    position: absolute;
                    font-size: 18px;
                    margin-left: 5px;
                    transition: 0.5s;
                    font-weight: bold;
                    font-family: 'permira' !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
        }
    }
}
