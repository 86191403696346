@import '../../styles/utils.scss';

.el{
    width: 100%;
    padding-top: 10px;
    // padding-bottom:40px;
    .container {
        max-width: $container-md;
    }
    .content{
        // border-top: 1px solid $color-blue-25;
        display:flex;
        align-items:flex-start;
        padding-top:60px;
        gap: 25px;
        @include tablet{
            flex-direction: column;
            padding:40px 0;
            gap: 0;
        }
        .leftSide {
            width: 50%;
            @include tablet{
                width: 100%;
            }
         h2{
            width:60%;
            @include tablet{
                width:100%;
                padding-bottom: 20px;
            }
         }
        }
        .rightSide {
            font-size: 18px;
            line-height: 130%;
            letter-spacing: -0.03em;
            padding-bottom: 25px;

            @include tablet {
              padding-bottom: 0;
            }

            ul{
              margin: 0 0 20px 20px;
              padding: 0;
              li{
                list-style: disc;
              }
            }
        }
    }
    .cta {
        margin-bottom:0px;
        border-bottom: 1px solid $color-blue-25;
        .container {
          display: flex;
          justify-content: flex-end;
        }
        .ctaLink {
          padding: 20px 0 20px 40px;
          border-left: 1px solid $color-blue-25;
        }
        .noCtaLink {
          padding: 20px 0 20px 40px;
        }
        a {
          color: $color-orange;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.2;
          text-transform: uppercase;
        }
      }
}
