@import '../../styles/utils.scss';

.section {
    --title-colour: #272560;
    --title-font-size: 24px;
    --title-max-width: calc(100% - 75px);
    --desc-display: none;
    --col-width: 100%;
    --col-1-display: none;
    --title-cursor: pointer;
    --title-after-display: flex;
    --desc-after-display: block;
    --desc-open-padding: 0 0 25px;
    --after-box-size: 50px;
    --before-arrow-right: 16px;

    @media (max-width: 767px) {
        --title-font-size: 20px;
        --after-box-size: 42px;
        --before-arrow-right: 12px;
    }

    margin-top: 40px;
    margin-bottom: 100px;

    .container {
        display: flex;
        flex-direction: column;
        max-width: $container-md;
    }

    h1 {
        margin-bottom: 25px;
    }

    a {
        color: $color-orange;
        text-decoration: underline;
    }

    @include mobile {
        margin-top: 20px;
    }

    &__linebreak {
        margin-bottom: 20px;
    }

    &__title {
        position: relative;
        margin: 0 0 35px;
        scroll-margin-top: 122px;
        cursor: var(--title-cursor);
        transition: all 0.2s;

        &.indent {
            padding-left: 8%;
        }

        .titleText {
            max-width: var(--title-max-width);
            color: var(--title-colour);
            font-family: $f-heading;
            font-style: normal;
            font-weight: 600;
            font-size: var(--title-font-size);
            line-height: 130%;
            letter-spacing: -0.03em;
            transition: all 0.2s;
        }

        .afterArrow {
            position: absolute;
            z-index: 10;
            top: calc(50% - 10px);
            right: var(--before-arrow-right);
            font-size: 20px;
            color: $color-orange;
            transform: scaleY(-1) translateY(5px);
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            display: var(--title-after-display);
            justify-content: center;
            align-items: center;
            width: var(--after-box-size);
            height: var(--after-box-size);
            border: 1px solid $color-blue-10;
            transform: translateY(-50%) scaleY(-1);
            transition: all 0.2s;
        }

        &.open {
            .titleText {
                color: $color-orange;
            }

            .afterArrow {
                transform: scaleY(1);
            }
        }
    }

    &__desc {
        position: relative;
        display: var(--desc-display);
        color: $color-blue;
        font-size: 18px;
        line-height: 120%;

        &.indent {
            padding-left: 8%;
        }
        p {
            margin-bottom: 30px;
        }
        ul {
            margin: 0 0 20px 20px;
        }
        ul,
        li {
            list-style-type: inherit;
            padding: 0;
        }

        &:empty {
            display: none !important;
        }

        &:after {
            display: var(--desc-after-display);
            content: '';
            position: absolute;
            bottom: 25px;
            left: 0;
            width: 100%;
            border-bottom: 1px solid $color-blue-25;
        }

        &.open, &:first-of-type {
            display: block;
            padding: var(--desc-open-padding);
        }
    }
}
