@import '../../styles/utils.scss';

.el {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: $color-blue;
  width: 100%;
  &:before {
    content: ' ';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
  &:hover {
    .overlay {
      opacity: 1;
      a {
        span {
          transform: translateX(10px);
        }
      }
    }
  }

  .imageWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 50px;
    @include desktop {
      padding: 20px;
    }
    .image {
      width: 100%;
      height: 100%;
      background-color: $color-blue;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .overlay {
    text-align: left;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: $color-blue-overlay;
    padding:30px;
    @include desktop{
      padding:10px;
    }
    
    .overlayWrap {
      container: overlayWrap / inline-size;
      display: flex;
      justify-content:space-between;
      align-items: flex-start;
      flex-direction: column;
      color: #fff;
      font-size: 20px;
      height: 100%;

      .companyName {
        h3 {
          font-weight: 600;
          color: $color-orange;
          font-size: min(15cqw, 28px);
        }
      }
      .companyDesc {
        padding-top:8px;
        p {
          font-size:18px;
          line-height: 23px;
          font-weight: 400;
          @include desktop {
            font-size:14px;
            line-height: 20px;
          }
        }
      }
      .cta {
        color: $color-orange;
        cursor: pointer;
      }
    }
  }

  img {
    padding: 50px;
    @include desktop {
      padding: 20px;
    }
  }

  .cta {
    display: flex;
    justify-content: flex-start;
    color:#fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    @include desktop{
      font-size: 12px;
      line-height: 1.2;
    }
    @include mobile {
      font-size: 10px;
    }
  }
}
