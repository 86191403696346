@use 'sass:math';
@use "sass:list";
@import '../../styles/utils.scss';

.el {
  position: relative;
  width: 100%;
  display: flex;
  max-width: $container-md;
  margin: 0 auto;
  background-color: $color-blue;
  cursor: pointer;
  aspect-ratio: 1/1;

  &.disabled {
    cursor: default;
  }

  &[data-open="true"] {
    .content {
      opacity: 1;
    }
  }

  .icon {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 20%;
    transition: all 0.5s;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50%;

      transition: all 0.5s;
    }
  }

  .content {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 25px;
    background-color: $color-blue;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: all 0.5s;

    .contentPosition {
      width: 100%;
      text-align: center;

      .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 15px;
      }
    }
  }

  &:hover {
    .icon img {
      transform: scale(1.25);
    }
  }
}
