@import '../../styles/utils/variables.scss';

.disclaimer {
    max-width: $container-md;
    padding: 30px;
    position: relative;
    &ReferenceNumber {
        position: absolute;
        top: 0;
        left: -10px;
        top: 10px;
        font-size: 10px;
    }
    &Description {
        font-size: 14px;
        line-height: 24px;
        & a {
            color: $color-orange;
        }
    }
}
