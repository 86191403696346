@import '../../styles/utils.scss';

.el {
  border-bottom: 1px solid $color-blue-25;
  padding-bottom: var(--section-margin-standard);

  .innerHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: $container-fhd;
    margin: 0 auto;

    @include tablet {
      flex-direction: column;
    }

    @include mobile {
      display: block;
    }

    .content {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      margin-top: 15%;

      @include mobile {
        width: 100%;
        border: none;
        margin-top: 5%;
      }

      @include tablet {
        width: 100%;
        border: none;
        margin-top: 5%;
      }

      .container {
        max-width: calc($container-md / 2);
        margin: 0;
        position: sticky;
        top: 140px;
        margin-bottom: 70px;

        @include tablet {
          margin-bottom: 5px;
        }
      }
    }

    .title {
      width: 88%;

      @include tablet {
        width: 100%;
      }

      span {
        color: $color-orange;
        display: block;
      }
    }

    .description {
      margin-top: 15px;
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
      width: 75%;

      @include tablet {
        width: 100%;
      }
    }

    .image {
      position: relative;
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-position: center;
      font-size: 0;
      line-height: 0;
      background-color: #000;

      span img {
        object-fit: cover;
      }

      @media screen and (min-width: 1800px) {
        padding-top: 900px;
      }

      @include mobile {
        width: 100%;
        padding-top: 100%;
        margin-top: 38px;
      }

      @include tablet {
        margin-bottom: 40px;
      }

      .videoWrapper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .overlay {
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0.8;
        width: 83%;
        max-width: 740px;

        svg {
          width: 100%;
          height: auto;
        }
      }

      .videoPoster {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        pointer-events: none;
        z-index: 10;
      }

      .playButton {
        position: absolute;
        cursor: pointer;
        top: 0%;
        left: 0;
        text-align: center;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .scrollGroup {
    display: flex;
    flex-direction: column;
    width: 50%;

    @include tablet {
      width: 100%;
    }
  }

  .fourColsInfo {
    width: 100%;

    .container {
      max-width: calc($container-md / 2);
      margin: 0;
      display: flex;
      flex-direction: row-reverse;
      padding: 0;

      @include tablet {
        height: inherit;
        flex-direction: column;
      }
    }

    .contentStats {
      width: 100%;
      padding-right: 0;
      padding-left: 20px;

      @include desktop {
        padding-right: 20px;
      }

      @include tablet {
        width: 100%;
        padding: 0 20px;
      }

    }

    .titleStats {
      text-align: left;
      margin-bottom: 40px;
      font-size: 46px;

      @include tablet {
        margin-top: 40px;
      }
    }

    .itemsStats {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // margin-bottom: 40px;
    }

    .itemStats {
      width: 50%;
      padding-right: 40px;
      margin-bottom: 64px;

      @include tablet {
        padding-right: 12.5px;
        margin-bottom: 65px;
      }

      &:nth-child(even) {
        padding-right: 0;

        @include tablet {
          padding-left: 12.5px;
        }
      }

      .iconImageStats {
        margin-bottom: 20px;
        max-width: 128px;

        @include tablet {
          max-width: 75px;
          margin-bottom: 14px;
        }
      }

      .descStats {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.03em;
        text-align: left;
        margin-top: 5px;
        max-width: 280px;

        ul {
          padding-left: 25px;
          margin-top: 10px;

          li {
            list-style: disc;
          }
        }

      }

    }

    .stats {
      padding: 20px 0px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 40px;

      .statsInfo {
        width: 50%;
        padding: 40px 40px 40px 0;

        // border-top: 1px solid $color-blue-25;
        .statsTitle {
          text-align: left;
          color: $color-orange;
          font-weight: 600;
        }

        .statsDesc {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: -0.03em;
          text-align: left;
          margin-top: 5px;
        }
      }
    }
  }
}
