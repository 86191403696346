@import '../../styles/utils.scss';

.el {
  --grid-template-column: 1fr;
  --display: block;

  @media (min-width: 1024px) {
    --grid-template-column: repeat(2, minmax(0, 1fr));;
    --display: grid;
  }

  display: var(--display);
  grid-auto-columns: 1fr;
  grid-template-columns: var(--grid-template-column);
  min-height: 100px;
  max-width: $container-md;
  gap: var(--section-margin-standard);
  margin: auto;
  padding: var(--section-margin-standard) var(--padding-standard) 0 var(--padding-standard);

  &:not(.cta-on) {
    border-bottom: 1px solid $color-blue-25;
    padding: var(--section-margin-standard) var(--padding-standard);
  }

  &.bg-white {
    background-color: #fff;
  }

  &.bg-grey {
    background-color: $color-grey;
  }

  .content1,
  .content2 {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--padding-standard);
    
    ul{
      margin: 0 0 20px 20px;
      padding: 0;
      li{
        list-style: disc;
      }
    }
  }
  .content1 {
    margin-bottom: var(--section-margin-standard);
  }
}

.cta {
  max-width: 1440px;
  margin: var(--section-margin-standard) auto 0;
  padding: 0 var(--padding-standard);
  border-bottom: 1px solid $color-blue-25;

  display: flex;
  justify-content: flex-end;

  .ctaLink {
    padding: 20px 0 20px 40px;
    border-left: 1px solid $color-blue-25;
  }

  a {
    color: $color-orange;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
