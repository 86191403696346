@import '../../styles/utils.scss';
.lifeAtPermiraBanner {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-top: 80px;
    max-width: $container-fhd;
    margin: 0 auto;
    @include tablet{
        flex-direction: column;
        padding-top: 48px;
    }


    .text {
        position: absolute;
        top: 140px;
        left: 0;
        width: 100%;
        z-index: 1;
        .container {
          max-width: $container-md;
          padding-bottom: 40px;
        }
        @include desktop {
            // padding: 0 $mobile-padding;
        }
        @include tablet{
            // width: 100%;
            // height: inherit;
            // padding-bottom: 60px;
            position: relative;
            top: 0;
        }
        @include mobile {

        }
        .title {
            padding: 0;
            max-width: 434px;
            @include desktop {
                width: 100%;
            }
        }

        .desc {
            margin-top: 30px;
            font-size: 18px;
            line-height: 23px;
            font-weight: 400;
            max-width: 434px;
            @include desktop {
                width: 100%;
            }
        }

        .button {
            text-align: left;
            margin-top: 43px;

            a {
                font-family: Graphik;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0.05em;
                color: $color-orange;
                display: block;
                align-items: center;
                margin: 10px 0;
                padding-bottom: 10px;
                span {
                    margin-left: 15px;
                    transition: all 0.2s ease-in-out;
                    font-size: 16px;
                    margin-top: 4px;
                    vertical-align: middle;
                }
                &:hover {
                  text-decoration: none;
                    span {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .media {
        width: 75%;
        height: 660px;
        @include tablet {
            width: 100%;
            height: 360px;
        }
        @include mobile {
            height: 240px;
        }
    }
    .banner {
        width: 100%;
        height: 100%;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .deco {
            width: 60%;
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0.8;
        }
        .mask {
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}
