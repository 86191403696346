@import '../../styles/utils.scss';

.el {
  width: 100%;
  padding-bottom: 180px;
  .container {
    max-width: $container-md;
    @include tablet {
      padding: 0 $mobile-padding;
    }
  }
  .twoColumnsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    @include mobile {
      grid-template-columns: 1fr;
    }
    .leftColumn {
    }
    .rightColumn {
    }
    .biographyList {
    }
  }
  .podcast {
    margin-bottom: 40px;
  }
  .articleText {
    margin-bottom: 55px;
    h4 {
      font-size: 24px;
      margin-bottom: 22px;
      text-align: left;
    }
    .articleDiv {
      margin-bottom: 30px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: -0.03em;
      text-align: left;
      :global(ul, li) {
        margin-left: 20px;
        list-style: disc;
      }
    }
  }
  .fourColsInfo {
    width: 100%;
    .stats {
      padding: 20px 0px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .statsInfo{
        width: 50%;
        padding: 40px 40px 40px 0;
        .statsTitle {
            text-align: center;
            color: $color-orange;
            font-weight: 600;
        }
        .statsDesc {
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: -0.03em;
            margin-top: 20px;
        }
      }
    }
  }
  .quoteWrapper {
    margin-top: 32px;
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 10px;
      width: 120px;
      height: 90px;
      background-image: url('/images/quote-background-top.png');
      background-position: top left;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .quoteAuthorName {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.03em;
      color: $color-blue;
      margin-right: 20px;
    }
    .quoteAuthorRole {
      font-family: 'Graphik';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: -0.03em;
      color: $color-blue;
      text-transform: uppercase;
      margin-top: 5px;
      display: inline-block;
    }
    .quote {
      padding: 48px;
      padding-bottom: 50px;
      color: $color-orange;
      box-sizing: border-box;
      margin-top: 60px;
      @include desktop-sm {
        height: auto;
      }
      @include tablet {
        padding: 40px 23px;
        padding-bottom: 100px;
        height: auto;
      }
      @include mobile {
        height: auto;
        padding-bottom: 100px;
      }
    }
    .quoteAuthor {
      display: flex;
      text-align: right;
      justify-content: flex-end;
      padding-right: 0;
    }
    .quoteTitle {
      font-size: 20px;
      line-height: 22px;
      font-style: italic;
      font-weight: 500;
      letter-spacing: -0.03em;
      text-align: left;
      position: relative;
      padding-bottom: 20px;
      @include tablet {
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.03em;
        text-align: left;
      }
      &:after {
        content: ' ';
        position: absolute;
        right: -20px;
        bottom: 0;
        width: 120px;
        height: 90px;
        background-image: url('/images/quote-background-bottom.png');
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
      }
    }
  }
  .biographyList {
    margin-top: 40px;
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.03em;
    color: $color-blue;
    .biographyItems {
      .accordionList {
        padding-top: 20px;
        padding-bottom: 40px;
      }
    }
    .biographyItem {
      display: flex;
    }
    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .biographyImageWrapper {
      width: 80px;
      height: 80px;
      min-width: 80px;
      min-height: 80px;
      border-radius: 80px;
      overflow: hidden;
      margin-right: 20px;
      .biographyImage {
        object-fit: cover;
        width: 100%;
      }
    }
    .biographyItemActive {
      .biographyImageWrapper {
        transform: scale(1.2);
        border: 2px solid $color-orange;
      }
    }
  }
  .biographyAuthor {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .biographyName {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-right: 20px;
    }
    .biographyRole {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.2;
      text-transform: uppercase;
      margin-top: 5px;
      display: inline-block;
    }
  }
  .tooltip {
    position: absolute;
    max-width: calc(100% - 10px);
  }
  .tooltipBody {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid $color-blue-40;
    backdrop-filter: blur(5px);
    padding: 12.5px;
    padding-top: 54px;
    border-radius: 10px;
    max-width: 100%;
    width: 400px;
  }
  .tooltipArrow {
    position: absolute;
    width: 10px;
    height: 10px;
    margin: 1px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      background: white;
    }
  }
  .tooltipArrowright {
    left: -6px;
    transform: translateY(40px);
    &::before {
      transform: rotate(45deg) skew(-10deg, -10deg);
      border-bottom: 1px solid $color-blue-40;
      border-left: 1px solid $color-blue-40;
    }
  }
  .tooltipArrowbottom {
    top: -6px;
    &::before {
      transform: rotate(135deg) skew(-10deg, -10deg);
      border-bottom: 1px solid $color-blue-40;
      border-left: 1px solid $color-blue-40;
    }
  }
}
