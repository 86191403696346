@import '../../styles/utils.scss';

.el {
    border-bottom: 1px solid $color-blue-25;
    padding-bottom: 65px;
    margin-top: -1px;
    .innerWrapper {
        border-bottom: 1px solid $color-blue-25;
        margin-bottom: 40px;
        padding-top: 60px;

        &:nth-child(odd) {
            background-color: $color-grey;
            &:after{
               content: " ";
               width:100%;
               position: absolute;
               height: 40px;
               background-color: $color-grey;
               margin-top: 1px;

            }
        }
        .container {
            max-width: $container-md;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            @include tablet {
              display: block;
            }

          }
        .itemLeft {
            width: 50%;
            @include tablet {
                width: 100%;
                padding: 10px 0px;
            }
            h2 {
                @include desktop {
                    width:80%;
                }
            }
        }
        .itemRight {
            width: 50%;
            @include tablet {
                width: 100%;
                padding: 0 20px;
            }
            .itemDetails {
                font-size: 18px;
                line-height: 23.4px;
                font-weight: 400;
                ul {
                    li {
                        list-style: disc;
                    }
                }
            }
            .itemFacts {
                padding: 40px 0 60px 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .statsInfo {
                    width: 50%;
                    padding-right: 40px;
                    .statsTitle {
                        font-size: 50px;
                        font-style: normal;
                        line-height: 1.2;
                        letter-spacing: -0.03em;
                        text-align: left;
                        color: $color-orange;
                        font-weight: 600;
                        @include tablet {
                            font-size: 36px;
                        }
                    }
                    .statsDesc {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23px;
                        letter-spacing: -0.03em;
                        text-align: left;
                        margin-top: 5px;
                    }
                }
            }
        }
        .cta {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .ctaLink {
                padding: 20px 0 20px 40px;
                border-left: 1px solid $color-blue-25;
            }
            a {
                color: $color-orange;
                font-size: 14px;
                font-weight: bold;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
    }
}

.investmentDetails{
    display: flex;
    flex-direction: column;
    padding:40px;
}
