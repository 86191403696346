@import '../../styles/utils.scss';
.el {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 140px;
  background: $color-grey;
  @include tablet {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .container {
    max-width: $container-md;
    @include tablet {
      height: inherit;
      flex-direction: column;
    }
    .content {
      display: flex;
      @include tablet {
        display: block;
      }
    }
    .title {
      padding-right: 40px;
      margin-bottom: 15px;
      @include tablet {
        padding-right: 0;
      }
    }
    .description {
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
    }

    .list {
      --item-width: 25%;
      --item-width-expanded: 50%;
      --other-items-width-when-expanded: 15%;

      &.threeItems {
        --item-width: 33%;
        --item-width-expanded: 50%;
        --other-items-width-when-expanded: 25%;
      }

      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      gap: 15px;
      width: 100%;
      margin-top: 100px;
      max-height: 460px;

      @include tablet {
        margin-top: 70px;
        flex-direction: column;
        height: inherit;
        max-height: inherit;
      }

      &.hasSelected {
        .item {
          width: var(--other-items-width-when-expanded);
          @include tablet {
            width: 100%;
          }
        }
      }

      .item {
        --padding: 30px;
        --minHeight: 400px;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: var(--minHeight);
        padding: var(--padding);
        width: var(--item-width);
        background-color: $color-blue;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        overflow: hidden;

        @include desktop {
          --padding: 20px;
          --minHeight: 450px;
        }
        
        @include tablet {
          --minHeight: none;

          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
        }

        &:last-child {
          margin-right: 0;
        }

        .itemInner {
          transition: all 0.3s ease-in-out;
        }

        .text {
          text-align: center;
          color: $color-blue-25;
          margin-top: 5px;
          margin-bottom: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          @include desktop {
            font-size: 20px;
          }
          @include tablet {
            font-size: 28px;
          }
        }

        .number {
          text-align: center;
          color: $color-blue-80;
        }

        .button {
            position: absolute;
            margin-top: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            left: 0;
            right: 0;
            font-size: min(14px, 1.7cqw);
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0.05em;
            text-align: right;
            text-transform: uppercase;
            color: $color-blue-80;
            white-space: nowrap;
            span {
                margin-top: 20px;
                font-size: 18px;
            }
            @include tablet {
                opacity: 0.7;
                position: relative;
                margin-top: 20px;
            }
        }

        .disclaimer,
        .content {
          display: none;
          opacity: 0;
          visibility: hidden;
          transition: all 0.0s ease-in;
          transition-delay: 1s;
          min-width: var(--investment-item-text-width);
        }

        .disclaimer {
          opacity: 0;
          position: absolute;
          width: calc(100% - var(--padding) * 2);
          bottom: var(--padding);
          color: #fff;
          font-size: 10px;
          line-height: 1.3;
          letter-spacing: -0.03em;
          text-align: justify;

          &:empty {
            display: none !important;
          }

          @include tablet {
            display: none;
            position: relative;
            bottom: 0;
            margin-top: var(--padding);
          }
        }
        &:hover {
            .button {
              color: $color-orange;
            }
        }

        &.selected {
          width: var(--item-width-expanded);
          background-color: $color-blue-80;
          @include tablet {
            width: 100%;
          }
          .itemInner {
            margin-top: -8px;
          }
          .number {
            color: $color-white;
          }
          .text {
            color: $color-orange;
          }
          .button {
            display: none;
          }
          .disclaimer,
          .content {
            display: block;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
