@import '../../styles/utils.scss';

.el {
    width: 100%;
    overflow: hidden;
    background-color: #FFF;

    .section {
        padding-top: 60px;
        padding-bottom: 30px;
        max-width: $container-fhd;
        margin: 0 auto;

        .title,
        .description {
            margin-bottom: 30px;
        }

        .description {
            font-size: 18px;
            line-height: 1.3;
            letter-spacing: -0.03em;
        }

        .container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 0.1fr 1fr;
            column-gap: 26px;
            row-gap: 20px;

            @include laptop {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
            }

            .map {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row: 2;

                @include laptop {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row: 2;
                }

                .info-box {
                    &__logo {
                        margin: 8px;
                        max-height: 72px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            .tab-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 6px;
                grid-column-start: 3;
                grid-column-end: 5;

                @include laptop {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row: 1;
                }

                .tab {
                    width: 140px;
                    padding-bottom: 19px;
                    padding-left: 8px;
                    padding-right: 8px;
                    background-color: #FFFFFF;
                    cursor: pointer;

                    @include laptop {
                        width: 120px;
                    }

                    &:hover {
                        opacity: .8;
                    }

                    &.active {
                        border-bottom: 3px solid #C9C9D7;

                        p {
                            color: #E67C25;
                        }
                    }

                    p {
                        text-align: center;
                        color: #272560;
                        font-size: 20px;
                        line-height: 26px;
                        font-weight: 600;
                        @include laptop {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }

            .tile-content-list {
                display: grid;
                grid-template-rows: 1fr 1fr 1fr;
                grid-column-start: 3;
                grid-column-end: 5;
                gap: 14px;

                @include laptop {
                    grid-column-start: 1;
                    grid-column-end: 5;
                    grid-row: 3;
                }

                .tile-item {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    align-items: center;
                    background-color: #F7F7F7;
                    padding: 12px 30px 12px 34px;
                    border: 3px solid #FFF;

                    @include laptop {
                        padding: 8px 14px 8px 14px;
                    }

                    &.active {
                        border: 2px solid #ED9D5B;
                        box-shadow: 0px 4px 20px 0px #00000040;
                    }

                    &-left {
                        &__logo {
                            max-height: 72px;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    &-right {
                        grid-column-start: 2;
                        grid-column-end: 4;
                        margin-left: 14px;

                        &__title {
                            color: #27255C;
                            font-size: 18px;
                            line-height: 23.22px;
                            font-weight: 600;
                        }

                        &__description {
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 20.64px;
                            color: #272560;


                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                        }

                        &__read-more {
                            color: #DB5C15;
                            text-align: right;
                            display: inline-block;
                            width: 100%;
                            font-weight: 600;

                            a {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

        }

        .pagination-wrapper {
            border: 1px solid #C9C9D7;
            display: flex;
            justify-content: flex-end;
            margin-top: 32px;

            .pagination-info {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 38px;
                border-left: 1px solid #C9C9D7;
                border-right: 1px solid #C9C9D7;

                p {
                    font-weight: 600;
                }
            }

            .pagination {
                display: flex;

                .button-prev,
                .button-next {
                    width: 64px;
                    height: 64px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &.disable {
                        opacity: .25;
                        pointer-events: none;
                    }

                    &:hover {
                        opacity: .7;
                    }
                }

                .button-next {
                    border: none;
                    border-left: 1px solid #C9C9D7;
                }
            }
        }

    }
}