.el {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  width: 100%;

  .title {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.15;
    letter-spacing: -0.03em;
  }

  .responsiveContainer {
    position: relative;
    width: 80%;
    display: flex;
    justify-content: center;
  }
}
