@import '../../styles/utils.scss';

.el {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: $container-fhd;
  margin: 0 auto;
  padding: var(--section-margin-standard) 0;

  .content {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @include tablet {
      width: 100%;
      border: none;
    }

    @include mobile {
      width: 100%;
      border: none;
    }

    .container {
      max-width: calc($container-md / 2);
      margin: 0;
      padding-right: 0;

      @include tablet {
        padding-left: 0;
      }

      .contentWrapper {
        @include tablet {
          width: 100%;
        }
      }
    }
  }

  .description {
    margin-top: 30px;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: -0.03em;
    
    ul{
      margin: 0 0 20px 20px;
      padding: 0;
      li{
        list-style: disc;
      }
    }
  }

  .image {
    position: relative;
    width: 50%;
    aspect-ratio: 16/9;
    background-size: cover;
    background-position: center;
    font-size: 0;
    line-height: 0;

    @include mobile {
      width: 100%;
      padding-top: 66%;
      margin-bottom: 38px;
    }

    @include tablet {
      width: 100%;
      padding-top: 66%;
      margin-bottom: 38px;
    }

    .videoWrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 0.8;
      width: 83%;
      max-width: 740px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    .videoPoster {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      pointer-events: none;
      z-index: 10;
    }

    .playButton {
      position: absolute;
      cursor: pointer;
      top: 0%;
      left: 0;
      text-align: center;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .stats {
    padding: 30px 0 30px 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include mobile {
      padding: 30px 0 30px;
    }

    .statsInfo {
      width: 50%;
      padding-right: 40px;

      .statsTitle {
        font-size: 50px;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.03em;
        text-align: left;
        color: $color-orange;
        font-weight: 600;

        @include tablet {
          font-size: 36px;
        }

        @include mobile {
          font-size: 26px;
        }
      }

      .statsDesc {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.03em;
        text-align: left;
        margin-top: 5px;
      }
    }
  }

  .cta {
    display: flex;
    justify-content: flex-start;

    .ctaLink {
      padding: 20px 0 20px 30px;
    }

    a {
      color: $color-orange;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }

  @include tablet {
    flex-direction: column-reverse;

    .image {
      margin-top: 38px;
      margin-bottom: 0;
    }
  }
}
