@import '../../styles/utils.scss';

.el {
  width: 100%;
  display: flex;
  max-width: $container-md;
  margin: 0 auto;
  padding: 5px 30px;

  @include mobile() {
    padding: 5px 20px;
  }

  &[data-open="true"] {
    .launcher .content {
      max-height: 350px;
    }
  }

  .launcher {
    width: 100%;
    margin: auto;
    padding: 5px;
    border: 1px solid $color-blue-40;
    cursor: pointer;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 0 15px;
      background: $color-blue-10;
      min-height: 95px;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding: 15px;
      }

      .label {
        font-size: 24px;
        font-weight: 700;
        color: $color-blue;

        @include mobile {
          font-size: 18px;
        }
      }

      .arrow {
        color: $color-orange;
        font-weight: bold !important;
        white-space: nowrap;

        &:hover {
          .icon-arrow-black:before {
            transform: translateY(-3px);
          }
        }

        .icon-arrow-black {
          display: inline-block;
          margin-left: 10px;

          &:before {
            display: block;
            height: 16px;
            font-weight: bold !important;
            color: black;

            transition: all 0.25s;
          }
        }

        .arrowUp {
          transform: scaleY(-1);
        }
      }
    }

    .content {
      max-height: 0px;
      overflow: hidden;

      transition: all 0.5s;

      .contentInner {
        padding: 15px;
      }
    }
  }
}