@import '../../styles/utils.scss';
@import '../../styles/componentShared.scss';

.el {
  padding-bottom: 40px;
  max-width: $container-fhd;
  margin: 40px auto 0;

  @include tablet {
    margin-top: 0;
  }

  &.people {
    padding-top: 20px;
    background-color: #F7F7F7;
  }

  /*.container {
    max-width: $container-md;
    overflow: hidden;
  }

  .title {
    text-align: left;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 40px;
    }
}*/

  .image {
    display: block;
    justify-content: center;
    align-items: center;
    width: 283px;
    height: 283px;
    background-color: $color-blue;
    text-align: center;
    position: relative;

    @include desktop {
      width: 203px;
      height: 203px;
    }
  }
}

.content {
  .slickArrows {
    @extend .slickArrowsStandard;
  }

  .items {
    margin-right: -18px;

    

    @include tablet {
      margin-bottom: 20px;
    }
  }
}

.carouselOverrides {
  /*:global(.slick-slider) {
    max-width: $container-md;
    overflow: visible;
  }

  :global(.slick-list) {
    overflow: visible;
  }*/

  :global(.slick-slide) {
    aspect-ratio: 1/1;

    &>div {
      height: 100%;
      width: auto;
    }

    margin-right: 36px;
    width: 283px !important;

    @include tablet {
      width: 160px !important;
      margin-right: 20px;

      &>div {
        height: 100%;
      }
    }
  }

  /*:global(.regular.slider) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  :global(.slick-track) {
    display: flex;
  }*/
}
