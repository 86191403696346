@import '../../styles/utils.scss';

.el {
  border-bottom: 1px solid $color-blue-25;
  .container {
    max-width: $container-md;
    display: flex;
    justify-content: space-between;
    @include tablet {
      display: block;
    }
  }
  .image {
    width: 50%;
    padding: 106px 4.16vw 80px 0;
    @include tablet {
      width: 100%;
      padding: 66px 0 0;
    }
    @include mobile {
      padding: 0;
    }
    img {
      width: 100%;
      max-width: 580px;
      height: auto;
    }
  }
  .content {
    width: 50%;
    border-left: 1px solid $color-blue-25;
    padding: 106px 0 80px 4.5vw;
    @include tablet {
      width: 100%;
      padding: 20px 0 90px;
      border-left: none;
    }
    .name {
    }
    .role {
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
    }
    .social {
      margin-top: 46px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: -0.03em;
        margin-right: 20px;
        & + div {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      a {
        display: inline-block;
        font-size: 36px;
        line-height: 1;
        color: $color-blue;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        span {
          display: block;
          margin-bottom: -8px;
        }
      }
    }
    .tags {
      margin-top: 10px;
      @include mobile {
        margin-top: 5px;
      }
      .tagItem {
        display: inline-block;
        position: relative;
        background: $color-orange;
        color: #fff;
        border-radius: 19px;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.2;
        letter-spacing: 0.05em;
        padding: 10px 20px;
        text-transform: uppercase;
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
    .description {
      margin-top: 16px;
      margin-bottom: 50px;
      font-size: 18px;
      line-height: 1.3;
      letter-spacing: -0.03em;
    }
    .contentBlock {
      margin-top: 20px;
      h4 {
        font-weight: bold;
        // font-size: 18px;
        // line-height: 26px;
        letter-spacing: -0.03em;
        margin-bottom: 5px;
      }
      .body, a {
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: -0.03em;
        color: $color-blue;
        &.underline {
          text-decoration: underline;
        }
      }
      a {
        text-decoration: underline;
      }
    }
  }
}
