@import '../../styles/utils.scss';

.header {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #C9C9D7;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  position: relative;
  @include tablet {
    padding-top: 20px;
  }
  &Wrapper {
    width: 50%;
    display: flex;
    align-items: center;
    height: 60px;
    @include tablet {
      width: 40%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 5px;

      &:first-child {
        width: 60%;
      }
    }
  }
  &-author {
    display: flex;
    align-items: center;
    margin-left: 10px;

    @include tablet {
      margin-left: 0;
    }
  }
  &-date {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  &-photo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    @include tablet {
      display: none;
    }
  }
  &-name {
    font-size: 14px;
    line-height: 23px;
    color: black;

    @include tablet {
      max-width: 80px;
      line-height: 18px;
    }
  }
  &-share-buttons {
    height: 60px;
  }
  &-share-title {
    font-weight: bold;
    margin-right: 20px;
  }
  .social {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &-title {
      font-weight: bold;
    }
    &-item {
      font-size: 34px;
      margin-right: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      @include tablet {
        font-size: 28px;
        margin-right: 15px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    right: 0;
    top: 40px;
    @include tablet {
      top: -40px;
    }
    span {
      width: 26px;
      height: 1px;
      background-color: $color-blue;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      &:first-child {
        transform: translate(-50%) rotate(45deg);
      }
      &:last-child {
        transform: translate(-50%) rotate(-45deg);
      }
    }
  }
}

.el {
  width: 100%;
  padding-bottom: 180px;
  & + :global(.recent-investments) {
    margin-top: -120px;
  }
  .container {
    max-width: $container-md;
    @include tablet {
      padding: 0 $mobile-padding;
    }
  }
  .image {
    width: 50%;
    padding: 106px 4.16vw 80px 0;
    @include tablet {
      width: 100%;
      padding: 66px 0 0;
    }
    @include mobile {
      padding: 0;
    }
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
    }
    &Wrapper {
      width: 50%;
      &:first-child {
        padding-right: 36px;
        position: sticky;
        top: 130px;
        align-self: flex-start;
      }

      .videoWrapper {
        width: 100%;
      }

      .video {
        width: 100%;
        margin-bottom: 30px;
        position: relative;
        video {
          width: 100%;
        }
        iframe {
          width: 100%;
        }
        .playIcon {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -28px 0 0 -28px;
          width: 56px;
          height: 56px;
          cursor: pointer;
        }
      }

      .headline {
      }

      @include tablet {
        width: 100%;
        &:first-child {
          padding-right: 0;
          margin-bottom: 40px;
          position: relative;
          top: inherit;
        }
      }

      .acceptButton {
        cursor: pointer;
        display: block;
        transition: 0.5s;
        background-color: $color-orange;
        padding: 15px 45px;
        position: relative;
        border-radius: 10px;
        text-transform: uppercase;
        color: #fff;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        border-radius: 40px;
        transition: all 0.5s;
        min-width: 230px;
        text-align: center;
        letter-spacing:1px;
        float: right;
      }
      .acceptButton:after {
        content: '\62';
        position: absolute;
        opacity: 0;
        top: 24%;
        font-size: 24px;
        // right: 0px;
        transition: 0.5s;
        font-family: 'permira' !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include tablet{
          top:23%;
        }
      }
      .acceptButton:hover {
        // padding-right: 24px;
        // padding-left: 8px;
        background-color: $color-orange-hover;
        border: none;
      }
      .acceptButton:hover:after {
        transform:translateX(10px);
        opacity: 1;
        // left: 100px;
      }

    }
    .article {
      width: 100%;
      position: relative;
      &-text {
        h4 {
          margin-bottom: 30px;
          text-align: left;
          @include tablet {
          }
        }
        &, p {
          margin-bottom: 30px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: -0.03em;
          text-align: left;

        }
        ul, ol {
          padding-left: 1em;
          margin: 1em 0;
          li {
            list-style-type: unset;
          }
        }
        ul {
          list-style-type: disc;
        }
        ol {
          list-style-type: decimal;
        }
      }
      &-image {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .download {
      position: relative;
      .treasersCard {
        overflow: hidden;
        transition: 0.5s;
        background-color: $color-grey;
        transition: 0.2s;
        &.noImage {
          background-color: $color-blue;
          min-height: 466px;
          @include mobile {
            height: 244px;
          }
        }
        &:hover {
          .treaserImageBox {
            transition: 0.3s ease;
            transform: translateX(-40px);
            img {
              -ms-transform: scale(1.1); /* IE 9 */
              -webkit-transform: scale(1.1); /* Safari 3-8 */
              transform: scale(1.1);
            }
          }
          .treaserCta{
            a{
              &:after{
                transform: translateY(5px);
              }
            }
          }
        }

        .treaserImageBox {
          overflow: hidden;
          object-fit: cover;
          img {
            object-fit: cover;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
            display: block;
            max-width: 100%;
            height: 466px;
            width: 100%;
            @include tablet{
              height: 244px;
            }
          }
        }

        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        margin-bottom: 40px;
        @include tablet {
          margin-bottom: 25px;
        }
        > div {
          width: 50%;
        }
        @include tablet{
          flex-direction: column;
          > div {
            width: 100%;
          }
        }
        .treaserCopy {
          .treaserDescription {
            p {
              color: #fff;
            }
            h3 {
              color: #fff;
            }
          }
          .treaserCta {
            a {
              color: #fff;
            }
          }
        }
        img {
          height: 414px;
          @include tablet{
            height: 244px;
          }
        }

        @include imageHoverEffect;
        background-color: #E9E9EF;
        .treaserImageBox{
          transition: 0.3s ease;
        }
        // common css
        .treaserCopy {
          padding: 20px 30px;
          transition: 0.3s;
          .treaserTitle {
            margin: 10px 0;
            width: auto;
            display: inline-block;
            p {
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 0.05em;
              background-color: $color-blue;
              padding: 5px;
              color: #fff;
              margin: 0;
              line-height: 15px;
            }
          }
          .treaserDescription {
            word-wrap: break-word;
            margin-top: 30px;
          }
          .treaserCta {
            padding: 65px 0 50px 0;
            a {
              color: $color-orange;
              text-transform: uppercase;
              font-weight: 700;
              font-size: 14px;
              letter-spacing: 0.05em;
              position: relative;
              span{
                display:none;
              }
              &:after {
                content: '\65';
                position: absolute;
                font-size: 18px;
                margin-left: 5px;
                margin-top: -2px;
                transition: 0.5s;
                font-weight: bold;
                font-family: 'permira' !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              }
            }
          }
        }
      }
    }
    .contact {
      font-size: 18px;
      font-style: normal;
      line-height: 23px;
      letter-spacing: 0.05em;
      text-align: left;
      margin-top: 48px;
      @include tablet{
        font-size: 14px;
        line-height: 17px;
      }
      & + .contact {
        margin-top: 15px;
      }
      &-title {
        font-weight: 700;
        padding-bottom: 25px;
        border-bottom: 1px solid #272560;
        @include tablet{
          padding-bottom: 10px;
        }
      }
      &-item {
        display: flex;
        border-bottom: 1px solid #C9C9D7;
        padding: 15px 0 10px 0;
        @include tablet {
          padding: 10px 0 5px 0;
        }
        &-title {
          width: 40%;
        }
        &-value {
          width: 60%;
          a {
            color: $color-blue;
          }
        }
      }
    }
  }
  .modalEl {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
  .modalContent {
    height: 100vh;
    overflow: auto;
  }
  .modalContentWrapper {
    margin: auto;
    position: relative;
    width: 66%;
    max-width: $container-md;
    margin-top: 130px;
    @include tablet {
      width: calc(100% - 40px);
      margin-top: 60px;
    }
  }
}
