@import '../../styles/utils.scss';

.container {
    max-width: $container-md;
}

.treaserWrapper {
    padding: 60px 0;
    columns: 2 auto;
    column-gap: 20px;

    @include tablet {
        padding: 40px 0;
        columns: 1 auto;
    }
    @media screen and (max-width: 991px) {
        columns: 1 auto;
    }

    .treasersCardContainer {
        padding-bottom: 20px;

        a {
          display: block;
          cursor: pointer;

          &:hover {
            text-decoration: none;
          }
        }

        &:nth-child(2) .treasersCard,
        &:nth-child(3) .treasersCard,
        &:nth-child(4) .treasersCard,
        &:nth-child(5) .treasersCard {
            flex-direction: row;

            > div {
                width: 50%;
            }

            @include tablet {
                flex-direction: column;
                > div {
                    width: 100%;
                }
            }

            .treaserCopy {
                .treaserDescription {
                    p {
                        color: #fff;
                    }
                }
                .treaserCta {
                    color: #fff;
                }
            }

            img {
                height: 100%;
                min-height: 414px;

                @include tablet {
                    height: 244px;
                }
            }

            @include imageHoverEffect;
        }

        &:nth-child(1) .treasersCard,
        &:nth-child(6) .treasersCard {
            max-height: 725px;
        }

        &:nth-child(2) .treasersCard,
        &:nth-child(4) .treasersCard {
            background-color: $color-blue;
        }

        &:nth-child(3) .treasersCard,
        &:nth-child(5) .treasersCard {
            background-color: $color-orange;

            .treaserTitle {
                h3 {
                    background-color: $color-blue;
                }
            }
        }
    }

    .treasersCard {
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: $color-grey;
        transition: 0.2s;

        &:hover {
            .treaserImageBox {
                transition: 0.3s ease;
                transform: translateY(-40px);
                img {
                    -ms-transform: scale(1.1); /* IE 9 */
                    -webkit-transform: scale(1.1); /* Safari 3-8 */
                    transform: scale(1.1);
                }
            }
            .treaserCta {
                &:after {
                    transform: translateX(20px);
                }
            }
        }

        &:first-child {
            width: 100%;
            flex-direction: column;
        }

        .treaserImageBox {
            overflow: hidden;
            object-fit: cover;
            transition: 0.3s ease;

            img {
                object-fit: cover;
                -webkit-transition: 0.4s ease;
                transition: 0.4s ease;
                display: block;
                max-width: 100%;
                height: 466px;
                width: 100%;
                @include tablet {
                    height: 244px;
                }
            }
        }

        .treaserCopy {
            padding: 20px 30px;
            transition: 0.3s;

            .treaserTitle {
                margin: 10px 0;
                width: auto;
                display: inline-block;

                h3 {
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    background-color: $color-orange;
                    padding: 5px;
                    color: #fff;
                    margin: 0;
                    line-height: 15px;
                }
            }

            .treaserDescription {
                word-wrap: break-word;

                p {
                    font-size: 18px;
                    color: $color-blue;
                }
            }

            .treaserCta {
                padding: 65px 0 50px 0;
                color: $color-orange;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 0.05em;
                position: relative;

                &:after {
                    content: '\62';
                    position: absolute;
                    font-size: 18px;
                    margin-left: 5px;
                    transition: 0.5s;
                    font-weight: bold;
                    font-family: 'permira' !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
        }
    }
}
