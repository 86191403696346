.el {
  display: flex;
  opacity: 0;
  transition: opacity 0.2s linear 0.2s;

  &.visible {
    opacity: 1;
  }
}

.countingNumber {
}

