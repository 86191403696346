@import '../../styles/utils.scss';

.el {
    padding-bottom: 40px;
    margin: auto;
    //border-bottom: 1px solid $color-blue-25;
    @include tablet {
      margin-top: 0;
    }
    &.grey {
      background-color: $color-grey;
    }
    &.white {
      background-color: $color-white;
    }
    &.people {
      padding-top: 20px;
      background-color: $color-grey;
    }
    .container {
      max-width: $container-md;
    }
  .title {
    text-align: left;
    padding-top: 60px;
    padding-bottom: 40px;

    @include tablet {
        padding-top: 40px;
    }
  }
  .description {
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: -0.03em;
    padding-bottom: 40px;
    
    ul{
      margin: 0 0 20px 20px;
      padding: 0;
      li{
        list-style: disc;
      }
    }
  }
}
