@import '../../styles/utils.scss';

.container {
    max-width: $container-md;
}

.officeWrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @include tablet {
        flex-direction: column;
    }


    .leftItem {
        width: 100%;
        gap: 30px;
        border-bottom: 1px solid $color-blue-25;
        margin-bottom: 60px;
        top: 130px;

        @include tablet {
            width: 100%;
            position: relative;
            top: 0;
        }

        h2 {
            text-transform: capitalize;
        }

        .regionWrapper {
            padding: 20px 0 20px 0;
            display: flex;

            .regionName {
                margin: 20px 80px;
                padding-left: 50px;

                span {
                    transition: 0.3s;
                    cursor: pointer;

                    &:hover {
                        color: $color-orange;
                    }

                    &.isActive {
                        color: $color-orange;
                    }
                }
            }
            @include tablet {
              flex-direction: column;
              .regionName {
                margin-left: 0;
              }
            }
        }
    }

    .rightItem {
        margin-bottom: 20px;
        width: 100%;

        @include tablet {
            width: 100%;
        }

        h4 {
            padding-bottom: 20px;

            @include tablet {
            }
            span {
              color: $color-orange;
            }
        }

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: $color-blue;
            margin-bottom: 10px;

            @include tablet {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .gotoLink {
            margin-top: 23px;

            a {
                color: #e67c25;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }

        .addressCards {
            .addressCard {
                margin-bottom: 20px;
                padding: 40px;

                background-color: #f7f7f7;
            }
        }

        .address,
        .tel {
          display: flex;
          gap: 15px;
        }

        .address {
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          color: $color-blue;
          margin-bottom: 10px;
          @include tablet {
              font-size: 14px;
              line-height: 20px;
          }
        }

        .tel {
            a {
                color: $color-blue;
                &:hover {
                    color: $color-orange;
                }
            }
        }
    }
}

.relatedOffices {
    .officeWrapper {
        padding-top: 0;
        .leftItem {
            border-bottom: none;
            margin-bottom: 40px;
        }
    }
}
.officeGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    @include mobile {
        grid-template-columns: 1fr;
    }
}
