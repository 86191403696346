@import '../../styles/utils.scss';

.el {
  width: 100%;
  margin: 60px 0;
  .container {

    max-width: $container-md;
  }
  .headline {
    padding-top: 60px;
    margin-bottom: 30px;
    border-top: 1px solid $color-blue-25;
  }
  .sponsors {
    margin: 40px 0 60px 0;
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 20px;
  }
  .sponsorItem {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $color-blue;
    width: auto;
    height: auto;
    padding: 57px;
    transition: all 0.3s ease-in;
    @media screen and (max-width: 720px) {
      // width: calc(50% - 36px);
    }
    &:hover {
      padding: 37px;
    }
    img {
      width: 100%;
    }
  }
  .filtersSort {
    display: flex;
    justify-content: flex-end;
    .sortSelect {
      width: 200px;
      min-width: 140px;
      border: 1px solid #C9C9D7;
      @include mobile {
        width: 140px;
      }
    }
  }
}
