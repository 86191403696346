@import '../../styles/utils.scss';
.el {
    width: 100%;
    .container {
        max-width: $container-md;
        padding: 90px $container-p;
        @include tablet {
            height: inherit;
            flex-direction: column;
            padding: 40px $mobile-padding;
        }
        .headline {
            @include tablet {
                margin-bottom: 35px;
            }
        }
        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            height: 720px;
            @include desktop-l {
                height: 50vw;
            }
            @include tablet {
                flex-direction: column;
                height: inherit;
            }
            &.hover {
                .scale {
                    width: calc((1440px - 80px) / 4);
                    height: calc((1440px - 80px) / 4);
                    @include desktop-l {
                        width: calc((100vw - 80px) / 4);
                        height: calc((100vw - 80px) / 4);
                    }
                    @include tablet {
                        width: calc(100vw - $mobile-padding * 2);
                        height: calc(100vw - $mobile-padding * 2);
                    }
                }
            }

            .item {
                width: calc((1440px - 80px) / 3);
                height: calc((1440px - 80px) / 3);
                background-color: $color-blue;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                @include desktop-l {
                    width: calc((100vw - 80px) / 3);
                    height: calc((100vw - 80px) / 3);
                }
                @include tablet {
                    width: calc(100vw - $mobile-padding * 2);
                    height: calc(100vw - $mobile-padding * 2);
                }
                .title {
                    text-align: center;
                    transition: all 0.2s ease-in-out;
                    @include tablet {

                    }
                }
                .button {
                    opacity: 0;
                    position: absolute;
                    margin-top: 20%;
                    transition: all 0.2s ease-in-out;
                    transition-delay: 0.2s;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0.05em;
                    text-align: right;
                    opacity: 0;

                    span {
                        margin-top: 20px;
                        font-size: 18px;
                    }
                    @include tablet {
                        opacity: 1;
                        margin-top: 40%;
                        opacity: 0.7;
                        margin-top: 130px;

                    }
                    // @include mobile {
                    //     margin-top: 50px;
                    // }
                }
                &.active {
                    width: calc((1440px - 80px) / 2);
                    height: calc((1440px - 80px) / 2);
                    @include desktop-l {
                        width: calc((100vw - 80px) / 2);
                        height: calc((100vw - 80px) / 2);
                    }
                    @include tablet {
                        width: calc(100vw - $mobile-padding * 2);
                        height: calc(100vw - $mobile-padding * 2);
                    }
                    .title {
                        font-size: 50px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: -0.03em;
                        text-align: center;
                    }
                    .button {
                        opacity: 1;
                        margin-top: 16%;
                        opacity: 0.7;
                    }
                }
            }
        }
    }
    .popup {
        width: 100vw;
        height: 100vh;
        min-height: 100vh;
        position: fixed;
        background-color: rgba(39, 37, 96, 0.9);
        top: 0;
        left: 0;
        z-index: 99;
        display: flex;
        justify-content: flex-end;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        @media screen and (max-width: 1366px) and (min-height: 1000px) {
          min-height: 100%;
          height: calc(var(--vh) - 0px);
        }
        @media screen and (max-width: 1366px) and (orientation: landscape) {
          min-height: 100%;
          height: calc(var(--vh) - 0px);
        }
        @include tablet {
          min-height: 100%;
          height: calc(var(--vh) - 0px);
        }
        @include mobile {
          min-height: 100%;
          height: calc(var(--vh) - 0px);
        }
        &.isPopupOpen {
            opacity: 1;
            .wrapper {
                transform: translateX(0);
                .main {
                    opacity: 1;
                    transition-delay: 0.2s;
                }
            }
        }
        &.isPopupClose {
            opacity: 0;
            transition-delay: 0.5s;
            .wrapper {
                transform: translateX(100%);
                transition-delay: 0.3s;
                .main {
                    opacity: 0;
                }
            }
        }
        .background {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(39, 37, 96, 0.9);
        }
        .wrapper {
            width: 50%;
            background-color: white;
            transform: translateX(100%);
            transition: all 0.4s ease-in-out;
            padding: 110px 40px 0 40px;
            position: relative;
            @include tablet {
                width: 100%;
                padding: 90px $mobile-padding 0 $mobile-padding;
            }
            @media screen and (max-width: 991px) and (orientation: landscape) {
                padding: 70px 40px 0 40px;
            }
            .main {
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all 0.2s ease-in-out;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-bottom: 60px;
                @include tablet {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-bottom: 0;
                }
            }
            .header {
                width: 100%;
                position: absolute;
                top: 0;
                .headline{
                    font-weight:600;
                    @media screen and (max-width: 991px) and (orientation: landscape) {
                        font-size:24px;
                    }
                }

                @include tablet {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    .headline {
                        padding-right: 25px;
                    }
                }
                @media (max-height: 630px) {
                    position: relative;
                }
            }
            .content {
                width: calc(100% + 80px);
                display: flex;
                flex-direction: column;
                @include tablet {
                    width: calc(100% + 40px);
                    margin-top: -10%;
                }
                @media (max-height: 645px) {
                    height: 630px;
                    overflow: auto;
                    padding: 60px 0;
                }
            }
            .navigate {
                height: 48px;
                display: flex;
                align-items: center;
                margin: 20px auto 30px auto;
                @include desktop-l {
                    margin: 0 auto 30px auto;
                }
                @include desktop {
                    margin: 15px auto;
                    padding-bottom: 15px;
                }
                @include mobile-m {
                    height: 30px;
                }
                &-item {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: $color-blue;
                    opacity: 0.5;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    @include mobile-m {
                        width: 15px;
                        height: 15px;
                    }
                    &.active {
                        width: 48px;
                        height: 48px;
                        @include mobile-m {
                            width: 30px;
                            height: 30px;
                        }
                        opacity: 1;
                    }
                }
            }
            .carousel {
                width: 100%;
                height: 476px;
                margin: 0 auto;
                @media (min-width: 1024px) and (max-height: 800px) {
                    height: 360px;
                }
                @include desktop {
                    height: 320px;
                }
                @include tablet {
                    height: calc(100vw - $mobile-padding * 2);
                }

                &-item {
                    width: 476px !important;
                    height: 476px;
                    border-radius: 50%;
                    background-color: $color-blue;
                    display: flex !important;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    margin: 0 auto;
                    @media (min-width: 1024px) and (max-height: 800px) {
                        width: 360px !important;
                        height: 360px;
                    }
                    @include desktop {
                        width: 320px !important;
                        height: 320px;
                    }
                    @include tablet {
                        width: calc(100vw - $mobile-padding * 2) !important;
                        height: calc(100vw - $mobile-padding * 2);
                    }
                    .title {
                        font-size: 50px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: -0.03em;
                        @include desktop {
                            font-size: 32px;
                        }
                        @media (min-width: 1024px) and (max-height: 800px) {
                            font-size: 36px;
                        }
                    }
                    .desc {
                        max-width: 280px;
                        margin-top: 18px;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23px;
                        letter-spacing: -0.03em;
                        text-align: center;
                        overflow: hidden;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                        @include desktop {
                            font-size: 14px;
                            margin-top: 10px;
                            max-width: 250px;
                        }
                    }
                }
            }
            .bottom {
                width: 100%;
                height: 60px;
                position: absolute;
                bottom: 0;
                left: 0;
                border: 1px solid $color-blue-25;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                background-color: white;
                overflow: hidden;
                .button {
                    width: 60px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-left: 1px solid $color-blue-25;
                    color: $color-orange;
                    span {
                        display: flex;
                        align-items: center;
                        margin-top: 5px;
                        transition: all 0.2s ease-in-out;
                    }
                    &.hide {
                        span {
                            opacity: 0.3;
                        }
                    }
                }
                .prev {
                    transform: scale3D(-1,1,1);
                    border-left: none;
                    border-right: 1px solid $color-blue-25;
                    &:hover {
                        span {
                            margin-left: 5px;
                        }
                    }
                }
                .next {
                  transform: scale3D(1,1,1);
                    &:hover {
                        span {
                            margin-right: 5px;
                        }
                    }
                }
            }
            .close {
                position: absolute;
                right: 0;
                top: 2px;
                width: 22px;
                height: 22px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    transform: scale(1.2);
                }
            }
            .close:before, .close:after {
              position: absolute;
              left: 15px;
              content: ' ';
              height: 23px;
              width: 1px;
              background-color: $color-blue;
            }
            .close:before {
              transform: rotate(45deg);
            }
            .close:after {
              transform: rotate(-45deg);
            }
        }
    }
}
