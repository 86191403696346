@import '../../styles/utils.scss';

.el {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  :global(.container) {
    max-width: $container-md;
  }
  .container {
    padding-top: 75px;
    padding-bottom: 0;
    // max-width: $container-md;
    @include mobile {
      padding-top: 28px;
    }
  }
}
