@import '../../styles/utils.scss';

.el {
  width: 100%;
  display: block;
  max-width: $container-fhd;
  margin: 0 auto;
  padding: 60px 0 60px 0;
  &NoPadding {
    padding: 0;
  }
}
