@import '../../styles/utils.scss';

.el {
  width: 100%;
  max-width: $container-md;
  display: block;
  margin: 60px auto 0;
  padding: 0 30px;

  &NoPadding {
    padding: 0;
  }

  .AccodionIconContainer {
    --display: flex;
    flex-direction: column;
    --grid-template-columns: 1fr;

    @media (min-width: 600px) {
      --display: grid;
      --grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 850px) {
      --grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 1200px) {
      --grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    display: var(--display);
    grid-template-columns: var(--grid-template-columns);
    gap: 20px;
    padding: 60px 0;
    border-top: 1px solid #c9c9d7;
  }
}
