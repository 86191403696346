@import '../../styles/utils.scss';

.el {
  // border-bottom: 1px solid $color-blue-25;
  margin: auto;
  border-bottom: 1px solid $color-blue-25;
  position: relative;
  @include tablet {
    margin-top: 0;
  }
  &.people {
    padding-top: 20px;
    background-color: #F7F7F7;
  }
  .container {
    max-width: $container-md;
  }
  .title {
    text-align: left;
    padding-top: 0;
    padding-bottom: 40px;

    @include tablet {
      padding-top: 40px;
    }
  }
  .description {
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: -0.03em;
    padding-bottom: 30px;
  }
  .content {
    .items {
      .image {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: left;
        position: relative;
        padding-bottom: 80px;
        @include tablet {
          margin: 0 0 40px;
          width: 100%;
        }
        @include mobile {
          margin: 0 0 20px;
          width: calc(50% - 20px);
        }
        &.grey {
          background-color: #F7F7F7;
        }
        &.white {
          background-color: #FFF;
        }
      }
    }
  }
  .pager {
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    .bar {
      height: 2px;
      background-color: $color-blue-25;
      display: inline-block;
      width: 100%;
    }
  }
  :global(.slick-dots) {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
