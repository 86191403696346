@import '../../styles/utils.scss';

.el {
  margin-top: 40px;

  @include tablet {
    margin-top: 0;
  }

  .container {
    max-width: $container-md;
  }

  .searchHeader {
    h2 {
      @include tablet {
        padding-top: 30px;
      }
    }

    padding-bottom: 35px;
  }

  // filter tabs
  .filterTabsWrapper {
    display: flex;
    border-bottom: 1px solid $color-blue-25;

    white-space: nowrap;

    @include desktop {
      overflow-x: scroll;
    }

    .filterTabs {
      margin: 0 10px;
      cursor: pointer;
      font-size: 18px;
      line-height: 23px;
      color: $color-blue;
      font-weight: 400;
      padding-bottom: 10px;

      &.isActive {
        border-bottom: 2px solid $color-blue;
        font-weight: 600;
      }
    }
  }

  .totalResults {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 15px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05em;
  }

  .resultsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 60px;

    @include tablet {
      padding-top: 30px;
    }

    .resultLink {
      width: 49%;
      color: $color-blue;
      margin-bottom: 20px;

      @include desktop {
        width: 100%;
        flex-grow: 2;
      }

      &:hover {
        text-decoration: none;
        color: $color-orange;
      }
    }

    .content {
      padding: 23px 15px;
      background-color: $color-grey;

      @include desktop {
        width: 100%;
        flex-grow: 2;
        padding: 20px 15px;
      }

      .tag {
        margin-bottom: 20px;
      }

      .tagBox {
        display: inline-block;
        padding: 3px 5px;
        margin-right: 9px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.05em;
        color: #fff;
        background: $color-orange;
        text-transform: uppercase;
      }

      .date {
        display: inline-block;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.2;
        letter-spacing: 0.05em;
      }

      .title {
        h4 {
          margin-bottom: 20px;
          transition: color 0.3s;

          @include tablet {
            line-height: 1.1;
          }
        }
      }

      .cta {
        margin-top: 43px;

        a {
          color: $color-orange;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.2;
          text-transform: uppercase;
        }
      }
    }
  }

  .paginationWrapper {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}
