@import '../../styles/utils.scss';

.el {
  margin-top: 40px;

  @include tablet {
    margin-top: 20px;
  }

  .container {
    max-width: $container-md;
    display: block;

    @include tablet {
      padding: 0 $mobile-padding;
    }
  }

  .wrapper {
    &:first-child {
      top: 100px;
      padding-bottom: 80px;
    }

    @include tablet {
      width: 100%;

      &:first-child {
        position: relative;
        top: inherit;
      }
    }
  }

  .content {
    padding-bottom: 60px;
  }

  .title {
    padding-right: 20px;

    @include tablet {
      padding-right: 0;
      margin-bottom: 20px;
    }
  }

  .item {
    border-top: 1px solid #C9C9D7;
    padding: 20px 0;

    b {}

    a {
      color: $color-orange;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.7;
      }
    }

    ul,
    li {
      list-style-type: inherit;
      margin: 0;
      padding: 0;
    }
  }

  .separator {
    border-top: 1px solid $color-blue-25;
    margin-bottom: 20px;
  }

  .contentItem {
    margin-bottom: 20px;
  }

  .bodyText {
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: -0.03em;

    p {
      margin-bottom: 20px;
    }

    ul {
      margin: 0 0 20px 20px;
    }

    ul,
    li {
      list-style-type: inherit;
      padding: 0;
    }

    a {
      color: $color-orange;
    }
  }
}
